<template>
  <div class="user-create-form">
    <form @submit.prevent="createUser" action="" class="auth__form app-form">
      <div class="app-form__header">Создать пользователя</div>
      <div class="app-form__content">
        <div class="app-form__field-list">
          <div v-for="(field, index) in formData.fields" :key="index" class="app-form__field-item">
            <div class="app-form__field-name">{{ field.title }}</div>
            <app-input
                v-model="field.value"
                :placeholder="field.placeholder"
                :name="field.name"
                :type="field.type"
                :class-name="'app-form__field'"
            />
          </div>
        </div>
        <app-button :value="'Отправить'" :type="'submit'" :class-name="'app-form__button'" />
      </div>

    </form>

    <app-message
        :className="'content-message'"
        ref="app-message"
    />
  </div>
</template>

<script>
import appInput from "../base-ui/app-input";
import appButton from "../base-ui/app-button";

export default {
  name: 'app-user-create-form',
  data: function () {
    return {
      formData: {
        title: 'Вход',
        fields: {
          login: {
            title: 'Логин',
            placeholder: 'Логин',
            type: 'text',
            name: 'shopId',
            value: ''
          },
          email: {
            title: 'Email',
            placeholder: 'Email',
            type: 'email',
            name: 'email',
            value: ''
          },
          password: {
            title: 'Пароль',
            placeholder: 'Пароль',
            type: 'test',
            name: 'test',
            value: ''
          },
          passwordDuplicate: {
            title: 'Повторите пароль',
            placeholder: 'Пароль',
            type: 'test',
            name: 'test',
            value: ''
          },
          userName: {
            title: 'Имя пользователя',
            placeholder: 'Имя',
            type: 'next',
            name: 'userName',
            value: ''
          }
        }
      }
    }
  },
  methods: {
    createUser: async function () {
      if (! this.formData.fields.email.value || ! this.formData.fields.password.value || ! this.formData.fields.login.value) {
        this.$refs['app-message'].open({
          type: 'warning',
          message: 'Все поля являются обязательными'
        })

        return;
      }

      if (this.formData.fields.password.value !== this.formData.fields.passwordDuplicate.value) {
        this.$refs['app-message'].open({
          type: 'warning',
          message: 'Пароли не совпадают'
        })

        return;
      }

      return await this.$store.dispatch('createUser', {
        password: this.formData.fields.password.value,
        login: this.formData.fields.login.value,
        email: this.formData.fields.email.value,
        name: this.formData.fields.userName.value
      })
        .then(res => {
          if (res && res.statusCode && res.statusCode === 401) {
            return this.$store.dispatch('logoutAccount')
          }

          if (res.message && res.message === 'login существует') {
            this.$refs['app-message'].open({
              type: 'warning',
              message: 'Логин уже существует'
            })

            return;
          }
          if (res.message && res.message === 'email существует') {
            this.$refs['app-message'].open({
              type: 'warning',
              message: 'Email уже существует'
            })

            return;
          }

          this.$refs['app-message'].open({
            type: 'success',
            message: 'Пользователь успешно создан'
          })

          return res;
        })
        .catch(() => {
          this.$refs['app-message'].open({
            type: 'warning',
            message: 'Что-то пошло не так. Повторите попытку.'
          })
        })
    }
  },
  components: {
    'app-input': appInput,
    'app-button': appButton,
  }
}
</script>

<style lang="scss">
.user-create-form {
}
</style>
