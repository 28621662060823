<template>
  <div class="user-list">
    <div class="user-list__wrap">
      <app-user-item v-for="userData in usersList" :data="userData" :key="userData.id" />
    </div>
  </div>
</template>

<script>
import appUserItem from './app-user-item';

export default {
  name: 'user-list',
  computed: {
    usersList: function () {
      return this.$store.state.user.userList;
    }
  },
  methods: {
    getUsers: function () {
      if (! this.$store.state.user.userList.length) {
        this.$store.dispatch('getUserList')
        .then(response => {
          if (response && response.statusCode && response.statusCode === 401) {
            return this.$store.dispatch('logoutAccount')
          }
        })
      }
    },

    getData: function () {
      this.getUsers()
    }
  },
  components: {
    'app-user-item': appUserItem
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss">
.user-list {
  &__wrap {
    display: flex;
    flex-wrap: wrap;
  }
}


</style>
