<template>
  <div class="users-settings">
    <div class="users-settings__header">
      <div class="users-settings__title section-title">Пользователи</div>
      <div class="users-settings__description section-description">Управление пользователями аккаунта.</div>
    </div>
    <div class="users-settings__create">
      <div class="users-settings__create-form">
        <app-user-create-form />
      </div>
    </div>
    <div class="users-settings__list">
      <app-user-list />
    </div>
  </div>
</template>

<script>
import userList from '../components/user/app-user-list';
import appUserCreateForm from '../components/user/app-user-create-form';

export default {
  name: 'users-settings',
  components: {
    'app-user-list': userList,
    'app-user-create-form': appUserCreateForm,
  }
}
</script>

<style lang="scss">
.users-settings {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;

  &__header {
    width: 100%;
  }

  &__create {
    width: 520px;
  }

  &__list {
    width: calc(100% - 550px);
    margin-left: 30px;
    flex-grow: 1;
  }
}
</style>
