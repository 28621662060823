<template>
  <div class="user-item app-container">
    <div class="user-item__header">
      <div class="user-item__status-name">{{ data.is_general ? 'Администратор' : 'Младший администратор' }}</div>
      <div class="user-item__status"></div>
    </div>
    <div class="user-item__body">
      <div v-if="data.name" class="user-item__name">Пользователь: <b>{{ data.name }}</b> ({{data.login}})</div>
      <div v-if="data.email" class="user-item__email">Email: <b>{{ data.email }}</b></div>

      <div v-if="! data.is_general" @click="removeUser(data.id)" class="user-item__btn-wrap">
        <app-button  :type="'button'" :className="'user-item__btn'" :value="'Удалить'"/>
      </div>
    </div>

    <app-message
        :className="'content-message'"
        ref="app-message"
    />

  </div>
</template>

<script>
import appButton from '../../components/base-ui/app-button'

export default {
  name: 'app-user-item',
  props: {
    data: {
      type: Object
    }
  },
  components: {
    'app-button': appButton
  },
  methods: {
    removeUser: async function (id) {
      await this.$store.dispatch('removeUser', { id })
        .then(response => {
          if (response && response.statusCode && response.statusCode === 401) {
            return this.$store.dispatch('logoutAccount')
          }
        })
        .catch(() => {
          this.$refs['app-message'].open({
            type: 'warning',
            message: 'Что-то пошло не так. Повторите попытку.'
          })
        })
    }
  }
}
</script>

<style lang="scss">
.user-item {
  margin-bottom: 15px;
  width: calc(50% - 7px);
  min-width: 420px;
  margin-bottom: 15px;

  display: flex;
  flex-direction: column;

  &:nth-child(2n -1) {
    margin-right: 14px;
  }

  &__header {
    padding: 25px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: var(--color-dark);
    border-bottom: 2px solid var(--color-border);

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__body {
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  &__email {
    margin-bottom: 25px;
  }

  &__status {
    width: 8px;
    height: 8px;
    border-radius: 50%;

    &_green {
      background-color: var(--color-green);
    }

    &_yellow {
      background-color: var(--color-yellow);
    }
  }

  &__btn-wrap {
    margin-bottom: 10px;
    max-width: 250px;

    &:last-child {
      margin-bottom: 0;
    }
  }

}
</style>
